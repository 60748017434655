@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auth button {
  align-items: center;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 40px;
  margin: 10px;
}

.auth button.active {
  background: #4645C2;
}

.auth button:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.auth button:hover {
  background: #0056b3;
}

.background-with-blur::after {
  @apply absolute inset-0 bg-gradient-to-br from-transparent to-[#363058] blur-[50px] pointer-events-none;
  content: '';
  width: 30%; /* Adjust the size of the blur effect area */
  height: 30%;
  top: 70%;
  left: 70%;
}


.text-gradient {
  @apply bg-clip-text text-transparent;
  background-image: linear-gradient(90deg, #565666, #800080);
  /* background-image: linear-gradient(90deg, #B7B7FF, #4F5EE5); */
}

.connect-wallet-btn {
  background: linear-gradient(0deg, #644FE5, #4645C2);
  padding: 20px 30px;
  border-radius: 30px;
  margin: 30px 0;
}

.connect-wallet-btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.card-background {
  /* background: linear-gradient(0deg, rgba(61, 61, 61, 0.2), rgba(61, 61, 61, 0.48)); */
  background: linear-gradient(0deg, rgb(0, 0, 0, 0.2), rgb(18, 17, 17, 0.48));
  /* background: rgba(61, 61, 61, 0.2); */
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.33);
  border-width: 1px 1px 0 1px;
}

.card-background-1 {
  background: linear-gradient(0deg, rgba(61, 61, 61, 0.2), rgba(61, 61, 61, 0.48));
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.33);
  border-width: 1px 1px 0 1px;
}

.card-background:hover {
  transform: scale(1.1);
}

.get-started-btn {
  padding: 10px 20px;
  border-radius: 30px;
  background: linear-gradient(0deg, #B7B7FF, #4F5EE5);
}

.get-started-btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.background-with-blur-protect::after {
  @apply absolute inset-0 bg-gradient-to-br from-transparent to-[#363058] blur-[100px] pointer-events-none;
  content: '';
  width: 50%; /* Adjust the size of the blur effect area */
  height: 50%;
  top: 0;
  left: 70%;
}

.user-number-card::before {
  content: '';
  display: block;
  background-image: url('/public/img/staticTop.png');
  background-size: cover;
  position: absolute;
  left: 100px;
  top: -90px;
  width: 170px;
  height: 170px;
}

.user-number-card::after {
  content: '';
  display: block;
  background-image: url('/public/img/staticBottom.png');
  background-size: cover;
  position: absolute;
  right: 50px;
  bottom: -150px;
  width: 300px;
  height: 300px;
}

.footer {
  background: linear-gradient(180deg, #36454F, #615e61);
  /* background: linear-gradient(180deg, #151623, #545889); */
}

.footer ul {
  padding: 0 20px;
  line-height: 3;
}

.footer ul li {
  font-size: 13px;
}

.footer ul li:first-child {
  font-size: 16px;
  font-weight: 800;
}

.text-charcoal {
  color: #36454F;
}

.text-purple {
  color: #800080;
}

#thank-you-message {
  display: none;
}

#thank-you-message.show {
  display: block;
}

#no-thank-you-message {
  display: none;
}

#no-thank-you-message.show {
  display: block;
}